<template>
	<v-container v-if="$can('view', 'Hosting')" fluid>
		<v-row>
			<v-col cols="3">
				<SidebarFilters
					title="Project Customization"
					:filter-name="filters"
				></SidebarFilters>
			</v-col>

			<v-col cols="9">
				<v-card>
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SidebarFilters from '@/components/SidebarFilters'

export default {
	components: {
		SidebarFilters,
	},
	data() {
		return {
			filters: [
				{ name: 'Domain', path: 'domain' },
				// { name: 'Colors', path: 'color' },
				// { name: 'Navbar', path: 'navbar' },
				// { name: 'Footer', path: 'footer' },
			],
		}
	},
}
</script>
